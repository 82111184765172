

/* eslint-disable react/jsx-no-undef */
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CUserRightsAppl, CResultEntryOutput, CRequestParam } from "../../types";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import UserSession from "../UserSession";
import axios from 'axios';

interface AddInProfileParamsProps {
    obj: CResultEntryOutput;
    selprofilecode: string;
    showDialog: boolean;
    callbackSave(obj: CResultEntryOutput, action: string): any;
    callbackCancel(obj: CResultEntryOutput, action: string): any;
}

const AddInProfileParams = (props: AddInProfileParamsProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'addinprofileparams' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [selectedParams, setSelectedParams] = useState<CRequestParam[]>([]);
    const [visible, setVisible] = useState<boolean>(false);

    const OkClick = async () => {

        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/addinprofileparams',
            JSON.stringify({
                mandantid: props.obj.listResEntry[0].sMandantID, reqid: props.obj.listResEntry[0].sReqID, obj: props.obj, paramstoadd: selectedParams, userid: UserSession.getLoggedInUser().UserID, selprofilecode: props.selprofilecode
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        // console.log(response?.data);        
       
        if (response?.data !== null) {
            //setResultMainData(response?.data);
            //response?.data.listResEntry.forEach((o: CResultEntry) => {
            //    if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
            //    if (o.sParamStatus === "3") setDisableValidationButton(false);
            //    binddata.push({ ...o });
            //});
            //setResultData(binddata);
            //setResDate(null);
            //setResTime(null);
            ////mainResultData = binddata;        
        };

        if (visible === true) {
            console.log(selectedParams)
            /* SaveParamData(ParamData);*/
        }
        /*setVisible(false)*/
        props.callbackSave (props.obj, "save")
    }

    const CancelClick = async () => {

        if (visible === true) {
            console.log(selectedParams)
            /* SaveParamData(ParamData);*/
        }
        props.callbackCancel(props.obj, "cancel")
        /*setVisible(false)*/
    }

    const handleGroupCheckbox = (event: CheckboxChangeEvent, sParamId: string) => {

        var tabElement: CRequestParam;
        if (event.value === true) { // something checked so add it to the list
            const binddata: CRequestParam[] = [...selectedParams];
            for (tabElement of props.obj.collParamUnderProfile) {
                if (tabElement.sParamId === sParamId) {
                    binddata.push({ ...tabElement });
                    break;
                }
            }
            setSelectedParams(binddata);
 
        }
        else {
            const binddata: CRequestParam[] = [];
            for (tabElement of selectedParams) {
                if (tabElement.sParamId !== sParamId) {
                    binddata.push({ ...tabElement });
                }
            }
            setSelectedParams(binddata);
        
        }
     
    };

    useEffect(() => {
        setSelectedParams([])
        setVisible(props.showDialog);
    }, [props.showDialog])

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title={mui(userRights, "dlgaddinprofileparams", "header", "Add Inprofile Params")} ></DialogTitleBar>} onClose={CancelClick}
                        contentStyle={{ overflow: 'hidden' }} width={'500px'}>
                        <div className="container-fluid" style={{ overflowX: 'hidden', width: '94%' }}>

                            {
                                props.obj.collParamUnderProfile !== null && props.obj.collParamUnderProfile.map((value: CRequestParam, index) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-3">
                                                    <Checkbox
                                                        key={value.sParamId}
                                                        onChange={(event: CheckboxChangeEvent) => handleGroupCheckbox(event, value.sParamId)}
                                                        checked={selectedParams.some((el: CRequestParam) => el.sParamId === value.sParamId)}
                                                        label={value.sParamId}
                                                    />
                                                </div>
                                                <div className="col-7">
                                                    {value.sParamName}
                                                </div>
                                                <div className="col-2">
                                                    {value.sInternComment}
                                                </div>
                                            </div>
                                            <div className="row"><div className="col">&nbsp;</div></div>
                                        </>
                                    );
                                })
                            }
                        </div>
                        <div className="row"><div className="col">&nbsp;</div></div>

                        <div className="row">
                            <div className="col">
                                <div className="button-right">
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        OkClick()
                                    }}>{mui(userRights, "common", "cmdOk", "Ok")}</Button>&nbsp;
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                        CancelClick()
                                    }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    );
}

export default AddInProfileParams;