import { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { addressDialog, addressSearchFilter, adrBdAKS, adrBdGrp, adrBdTrf, CAddressData, CAdrExtPID, CAdrPatBld, COneAddress, CSearchAdrExtTS, ctDataType, CUserRightsAppl, ListItemData, MULTI_SELECT_TABLES, PLZObject, selCodeDataType, dlgScannerUIEmptyState, enmScanning, emptyScanProperties, CScanProperties, viewScanDialog, emptyAddress } from '../../../types';
import { ContextMenu, MenuItem, TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { DialogTitleBar, loadFromStorage, mui, PatientInformationMain, TabTitle } from "../../../Helper";
import axios from 'axios';
import Splash from "../../Misc/Splash";
import UserSession from "../../UserSession";
import { Button } from "@progress/kendo-react-buttons";
import ScannerUI, { scannerUIProps } from "../ScannerUI/ScannerUI";
import { Offset } from "@progress/kendo-react-popup";
import AddressTab from "./Address";
import PatientTab from "./Patient";
import OtherTab from "./Other";
import RemarksTab from "./Remarks";
import AdrBdTab from "./AdrBd";
import AdrPatBldTab from "./AdrPatBld";
import AdrExtPIDTab from "./AdrExtPID";
import TypeScreenTab from "./TS";
import { getWorkstationID } from "../../../Twain";
import ViewScan from "../ViewScan/ViewScan";

let source: any = null;

const AddressDialog = (props: addressDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'Address' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;
    const [visible, setVisible] = useState<boolean>(props.data.showDialog); // Visibility of address dialog
    const [title, setTitle] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [isLoading, setIsLoading] = useState({ loading: false, message: "" });
    const [plzDataSelFromGrid, setPLZDataSelFromGrid] = useState<PLZObject | null>(null);

    const dlgViewScanEmptyState: viewScanDialog = {
        sMandantID: "", sPID: "", sReqID: "", sBldBgNr: "", sBldBgType: "", sMaterialID: "", sLotID: "", enm: enmScanning.Scan_ExternLabResults, showDialog: false, callbackDialogClosed: () => { }
    }
    const [viewScanDialogData, setViewScanDialogData] = useState<viewScanDialog>(dlgViewScanEmptyState);

    useEffect(() => {
        source = axios.CancelToken.source();

        return () => {
            if (source) {
                source.cancel("Component got unmounted");
            }
        };
    }, [])

    // ***************************************************************************
    // ADR BD tab
    const [adrBdGrpData, setAdrBdGrpData] = useState<adrBdGrp[]>([]);
    const [adrBdAksData, setAdrBdAksData] = useState<adrBdAKS[]>([]);
    const [adrBdTrfData, setAdrBdTrfData] = useState<adrBdTrf[]>([]);
    // ***************************************************************************

    const callbackHandleChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        if (source === 'birthdate') {
            setData(prevData => ({
                ...prevData,
                sBirthDate: value
            }))
        } else if (source === 'firstusedate') {
            setData(prevData => ({
                ...prevData,
                sFirstUseDate: value
            }))
        } else if (source === 'statusdate') {
            setData(prevData => ({
                ...prevData,
                sStatusDate: value
            }))
        };
    };
    const HandleAdrPatBldDataChange = (value: CAdrPatBld[]) => {
        if (oneData !== null) {
            var binddata: COneAddress = oneData;
            binddata.AdrPatBldData = value;
            setOneData(binddata);
        };        
    };
    const HandleAdrExtPIDDataChange = (value: CAdrExtPID[]) => {
        if (oneData !== null) {
            var binddata: COneAddress = oneData;
            binddata.AdrExtPIDData = value;
            setOneData(binddata);
        };     
    };
    const HandleAdrExtTSDataChange = (value: CSearchAdrExtTS[]) => {
        if (oneData !== null) {
            var binddata: COneAddress = oneData;
            binddata.AdrExternTSData = value;
            setOneData(binddata);
        };
    };
    const callbackHandlePLZChange = (value: any) => {
        setPLZDataSelFromGrid(value);
    };
        
    const [scannerUIData, setScannerUIData] = useState<scannerUIProps>(dlgScannerUIEmptyState);      

    // actual pat data related
    const emptyOneAddress: COneAddress = {
        objAddress: emptyAddress,
        AdrBdAKSData: [],
        AdrBdGrpData: [],
        AdrBdTrfData: [],
        AdrPatBldData: [],
        AdrExtPIDData: [],
        AdrExternTSData: [],
        BGDesc: "",
        RHDesc: "",
        PTDesc: "",
        KellDesc: "",
        SexDesc: "",
        ReqCount: "",
        ActiveFID: "",
        Age:"",
        sMsg: "",
        DisabledScanControls: false,
        DisableScanPDF: false,
        DisableShowDocButton: false,
        DefReqSplComment1Color: "",
        DefReqSplComment2Color: "",
        ReqSplComment1Color: "",
        ReqSplComment2Color: ""
    };

    const [data, setData] = useState<CAddressData>(emptyAddress);
    const [oneData, setOneData] = useState<COneAddress | null>(null);

    // All code tables related to main address data
    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        setData(prevData => ({
            ...prevData,
            [controlID]: objCT.sCode
        }));
    };
    // For rep to email
    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {
        setData(prevData => ({
            ...prevData,
            sRepToEmail: savedCodes
        }))
    };

    // For Address Type
    const callbackAdrTypeSelected = (items: ListItemData[]) => {
        var adrtypes: string = "";        
        if (items.length > 0) {
            items.forEach((item, index) => {
                if (adrtypes === "") {
                    adrtypes = item.id;
                } else {
                    adrtypes = adrtypes + '�' + item.id;
                };
            });
            setData(prevData => ({
                ...prevData,
                sAddressTypes: adrtypes
            }))
        } else {
            setData(prevData => ({
                ...prevData,
                sAddressTypes: ""
            }))
        };

    };

    // All textbox and checkboxes related to main address data
    const handleChangeMainData = (e: any) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleTabSelect = (e: TabStripSelectEventArguments) => {
        setSelectedTab(e.selected);
    };

    // Select code component callback for all selections on main address data
    const callbackCodeSelected = async (tableid: string, controlID: string, obj: selCodeDataType) => {
        setData(prevData => ({
            ...prevData,
            [controlID]: obj.code
        }))
    };

    const clearForm = (): any => {
        setData(emptyAddress);
        setOneData(null);
        setAdrBdGrpData([]);
        setAdrBdAksData([]);
        setAdrBdTrfData([]);
    }

    useEffect(() => {
        setViewScanDialogData(dlgViewScanEmptyState);
        setSelectedTab(0);
        setVisible(props.data.showDialog);
        clearForm();
        if (props.data.loadPID === '') {
            setTitle("New Address");
        }
        else {
            setTitle("Edit Address"); // + props.data.loadPID
            const getPat = async () => {
                try {
                    var addressRetrieveFilter: addressSearchFilter = {
                        emptySearch: false,
                        mandantid: UserSession.getLoggedInUser().CurrentMandant,
                        firstname: "",
                        lastname: "",
                        pid: props.data.loadPID,
                        birthdateinternal: "",
                        reqid: "",
                        casenr: "",
                        datefrominternal: "",
                        datetointernal: "",
                        reqprov: {
                            MandantID: "",
                            OrdProvCode: "",
                            Name: "",
                            FirstName: ""
                        },
                        phonetic: false,
                        patWithRequests: false,
                        workstationid: loadFromStorage("workstationid")
                    };

                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'address/search',
                        JSON.stringify(addressRetrieveFilter),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json',
                            },
                            withCredentials: false,
                            cancelToken: source.token
                        }
                    );
                    if (response?.data !== null) {
                        const addresses: COneAddress[] = response?.data;
                        // console.log(response?.data);
                        if (addresses.length > 0) {
                            const objAddress: COneAddress = addresses[0];
                            
                            objAddress.objAddress.BillYN = objAddress.objAddress.sBillYN === "1" ? true : false;
                            objAddress.objAddress.DoNotUseForStatsYN = objAddress.objAddress.sDoNotUseForStatsYN === "1" ? true : false;
                            objAddress.objAddress.DoNotUseEPDYN = objAddress.objAddress.sDoNotUseEPDYN === "1" ? true : false;
                            objAddress.objAddress.GeneralConsentYN = objAddress.objAddress.sGeneralConsentYN === "1" ? true : false;
                            objAddress.objAddress.WarmTransfusionYN = objAddress.objAddress.sWarmTransfusionYN === "1" ? true : false;
                            objAddress.objAddress.ExtBloodGrpYN = objAddress.objAddress.sExtBloodGrpYN === "1" ? true : false;
                            objAddress.objAddress.NotifyPassMandatoryYN = objAddress.objAddress.sNotifyPassMandatoryYN === "1" ? true : false;
                            objAddress.objAddress.NotifyOnlyNegResultYN = objAddress.objAddress.sNotifyOnlyNegResultYN === "1" ? true : false;
                            objAddress.objAddress.DoNotPrintNotificationYN = objAddress.objAddress.sDoNotPrintNotificationYN === "1" ? true : false;
                            setData(objAddress.objAddress);
                            setOneData(objAddress);                                                       

                            var binddata: adrBdGrp[] = [];
                            if (objAddress.AdrBdGrpData !== null && objAddress.AdrBdGrpData !== undefined)
                                for (const o of objAddress.AdrBdGrpData) {
                                    binddata.push({ ...o, ReqDateDisplay: o.sReqDate + ' ' + o.sReqTime, ShowRedTickDisplay: o.ShowRedTick ? "1" : "0" });
                                }
                            setAdrBdGrpData(binddata);
                            var binddata1: adrBdAKS[] = [];
                            if (objAddress.AdrBdAKSData !== null && objAddress.AdrBdAKSData !== undefined)
                                for (const o of objAddress.AdrBdAKSData) {
                                    binddata1.push({
                                        ...o, ReqDateDisplay: o.sReqDate + ' ' + o.sReqTime,
                                        AKSVALIDDateDisplay: o.sAKSVALIDDate + ' ' + o.sAksValidTime
                                    });
                                }
                            setAdrBdAksData(binddata1);

                            var keyField: number = 1;
                            var binddata2: adrBdTrf[] = [];
                            if (objAddress.AdrBdTrfData !== null && objAddress.AdrBdTrfData !== undefined)
                                for (const o of objAddress.AdrBdTrfData) {
                                    binddata2.push({
                                        ...o, ReqDateDisplay: o.sReqDate + ' ' + o.sReqTime,
                                        DeliveryDateDisplay: o.sDeliveryDateDate + ' ' + o.sDeliveryDateTime,
                                        TransDateDisplay: o.sTransDateDate + ' ' + o.sTransDateTime,
                                        ReservedDateDisplay: o.sReservedDate + ' ' + o.sReservedTime,
                                        KeyField: keyField.toString()
                                    });
                                    keyField += 1;
                                }
                            setAdrBdTrfData(binddata2);

                           
                        }
                    }

                }
                catch (err: any) {
                    console.log(err);
                    if (!err?.response) {
                    } else if (err.response?.status === 401) {
                    } else {
                    }
                }
                setIsLoading({ loading: false, message: "" });
            }
            setIsLoading({ loading: true, message: "" });
            getPat();
        }
    }, [props.data.loadPID, props.data.showDialog])

    // Form button actions
    const deleteAddress = () => {
        props.callbackAddressDialogClosed("");
        setVisible(false);
    };
    const showPatScanData = () => {
        setViewScanDialogData({
            sMandantID: UserSession.getLoggedInUser().CurrentMandant,
            sPID: data.sPID,
            sReqID: "",
            sBldBgNr: "",
            sBldBgType: "",
            sMaterialID: "",
            sLotID: "",
            enm: enmScanning.Scan_PatDocs,
            showDialog: true,
            callbackDialogClosed: () => { }
        });
    };

    const cancelDialog = () => {
        props.callbackAddressDialogClosed("");
        setVisible(false);
    };

    async function saveDialog()  {        
        if (CheckMandatoryData()) {
            console.log('oneData', oneData);
            if (data !== null) {
                var binddata: COneAddress
                if (oneData === null) {
                    binddata = emptyOneAddress;                    
                } else {
                    binddata = oneData;
                };                
                binddata.objAddress = data;
                binddata.objAddress.sBillYN = binddata.objAddress.BillYN === true ? "1" : "0";
                binddata.objAddress.sDoNotUseForStatsYN = binddata.objAddress.DoNotUseForStatsYN === true ? "1" : "0";
                binddata.objAddress.sDoNotUseEPDYN = binddata.objAddress.DoNotUseEPDYN === true ? "1" : "0";
                binddata.objAddress.sGeneralConsentYN = binddata.objAddress.GeneralConsentYN === true ? "1" : "0";
                binddata.objAddress.sWarmTransfusionYN = binddata.objAddress.WarmTransfusionYN === true ? "1" : "0";
                binddata.objAddress.sExtBloodGrpYN = binddata.objAddress.ExtBloodGrpYN === true ? "1" : "0";
                binddata.objAddress.sNotifyPassMandatoryYN = binddata.objAddress.NotifyPassMandatoryYN === true ? "1" : "0";
                binddata.objAddress.sNotifyOnlyNegResultYN = binddata.objAddress.NotifyOnlyNegResultYN === true ? "1" : "0";
                binddata.objAddress.sDoNotPrintNotificationYN = binddata.objAddress.DoNotPrintNotificationYN === true ? "1" : "0";

                if (plzDataSelFromGrid === null) {
                    binddata.objAddress.sDomicileID = ""
                    binddata.objAddress.sDomicilePLZ = ""
                    binddata.objAddress.sDomicileCity = ""
                    binddata.objAddress.sDomicileCountry = ""
                } else {
                    binddata.objAddress.sDomicileID = plzDataSelFromGrid.sCode
                    binddata.objAddress.sDomicilePLZ = plzDataSelFromGrid.sPLZ
                    binddata.objAddress.sDomicileCity = plzDataSelFromGrid.sCity
                    binddata.objAddress.sDomicileCountry = plzDataSelFromGrid.sCountry
                };
                const SaveAddress_URL = UserSession.getSystemSettings().API_URL + 'address/saveaddressdata';
                const response = await axios.post(SaveAddress_URL,
                    JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, objOneAddress: binddata, autosavefid:true, userid: UserSession.getLoggedInUser().UserID }),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: false
                    }
                );
                if (response?.data !== null) {
                    binddata = response?.data;
                    if (binddata.sMsg !== "" && binddata.sMsg !== undefined) {
                        alert(binddata.sMsg);
                    } else {    
                        console.log('binddata', binddata);
                        setOneData(binddata);
                        setData(binddata.objAddress);
                        //console.log('new pid', binddata.objAddress.sPID);
                        props.callbackAddressDialogClosed(binddata.objAddress.sPID!==undefined?binddata.objAddress.sPID:"");
                        setVisible(false);
                    };
                };
            };            
        };       
    };

    function CheckMandatoryData() {
        var bok: boolean = true;
        var sMsg: string = ""
        if (data.sLastName === "") {
            sMsg = mui(userRights, "Address", "msgEnterLastName", "Enter Last Name!")
            alert(sMsg);            
            bok = false
        }
        else if (data.sFirstName === "") {
            sMsg = mui(userRights, "Address", "msgEnterFirstName", "Enter First Name!")
            alert(sMsg);
            bok = false
        }
        else if (data.sBirthDate === "") {
            sMsg = mui(userRights, "Address", "msgEnterBirthdate", "Enter Birthdate!")
            alert(sMsg);
            bok = false
        }
        else if (data.sSex === "") {
            sMsg = mui(userRights, "Address", "msgEnterGender", "Enter Gender!")
            alert(sMsg);
            bok = false
        }
        else if (data.sAddressTypes === "") {
            sMsg = mui(userRights, "Address", "msgEnterAdrType", "Enter Address Type!")
            alert(sMsg);
            bok = false
        }
        else if (data.sBloodGroup !== "" && data.sRhesus === "") {
            sMsg = mui(userRights, "Address", "msgEnterRhesus", "Enter Rhesus!")
            alert(sMsg);            
            bok = false
        }
        else if (data.sRhesus !== "" && data.sBloodGroup === "") {
            sMsg = mui(userRights, "Address", "msgEnterBG", "Enter Blood Group!")
            alert(sMsg);            
            bok = false       
        }        
        return bok;
    };

    // Context menu related
    const [show, setShow] = useState(false);
    const handleOnClose = () => {
        setShow(false);
    };
    const handleOnSelect = (e: any) => {
        var obj: CScanProperties = emptyScanProperties;
        obj.pid = data.sPID;        
        switch (e.item.data.action) {
            case "scan":
                // Scan Patient data and // Scan Patient PDF
                setScannerUIData({
                    source: enmScanning.Scan_PatDocs,
                    showDialog: true,
                    multipleScans: true,    
                    selPDF: false,
                    objScanProperties:obj,
                    ScanningOver: () => { }
                });
                break;
            case "pdf":
                // Scan Patient data and // Scan Patient PDF
                setScannerUIData({
                    source: enmScanning.Scan_PatDocs,
                    showDialog: true,
                    multipleScans: false,
                    selPDF: true,
                    objScanProperties: obj,
                    ScanningOver: () => { }
                });
                break;
        }
        setShow(false);
    };
    const [offset, setOffset] = useState<Offset>({ top: 0, left: 0 });
    const handleContextMenu = (e: any) => {
        setOffset({ left: e.pageX, top: e.pageY });
        e.preventDefault();
        setShow(true);
    };

    const ScanningOver = (source: string, success: boolean, data: string[], extlab: string, key2: string) => {
        // console.log('AddressDialog.onePageScanned', source, success, data !== "" ? "data" : "no data");
        setIsLoading({ loading: false, message: "" });
        setScannerUIData(dlgScannerUIEmptyState);
        if (success) {
            setScannerUIData(dlgScannerUIEmptyState);
            SaveScanData(data, source);
        } else {
            setScannerUIData(dlgScannerUIEmptyState);
        };
    };

    async function SaveScanData(arrScanData: string[], filetype: string) {
        const SaveCaseData_URL = UserSession.getSystemSettings().API_URL + 'scan/SaveScanDataAsPerScanType';
        const response = await axios.post(SaveCaseData_URL,
            JSON.stringify({
                sMandantID: UserSession.getLoggedInUser().CurrentMandant, sScannedFilePathName: "",
                nPageNr: 0, enm: enmScanning.Scan_PatDocs, sPID: data.sPID, sIDFrgCard: "", sReqID: "", sBldBgNr: "", sBldBgType: "",
                bShowDoc: true, nDocumentNr: 0, sExternLab: "", sKey2: "", sMaterialID: "", sMaterialDesc: "",
                sLotID: "", bNewScanSaved: false, sFileType: filetype, sScanExtLabResultDocumentNr: "",
                arrScanData: arrScanData, sUserID: UserSession.getLoggedInUser().UserID, bDisableShowDocButton: oneData?.DisableShowDocButton
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objSC = response?.data;
            if (objSC.bNewScanSaved === true) {
                if (oneData !== undefined) {
                    var obj: any = { ...oneData };
                    obj.DisableShowDocButton = false;
                    setOneData(obj);
                };              
            };
        };
    };            

    const callbackViewScanDialogClosed = () => {
        setViewScanDialogData(dlgViewScanEmptyState)
    };   

    var formWidth: number = 1400;
    var contentContainerWidth: number = formWidth - 80;
    var contentContainerParentWidth: number = formWidth - 60;
    var windowHeight: number = 720;


    return (
        <>
            <div>
                {visible && (
                    <Window title={<DialogTitleBar title={title + ' ' + PatientInformationMain(oneData)}></DialogTitleBar>} onClose={cancelDialog} maximizeButton={() => null}
                        height={windowHeight} width={formWidth} top={90} style={{ minWidth: formWidth, visibility: props.data.inViewport ? 'visible' : 'hidden' }} >
                        {isLoading.loading && <Splash message={isLoading.message}></Splash>}
                        {!isLoading.loading &&
                            <>
                            <div className="container-fluid" style={{
                                padding: 0, overflowY: 'hidden', maxWidth: contentContainerParentWidth, overflowX: "hidden",
                                height: windowHeight-100
                            }}>
                                <div className="row">
                                    <div className="col">
                                        <TabStrip selected={selectedTab} onSelect={handleTabSelect} style={{
                                            padding: 0, width: contentContainerParentWidth,
                                            minWidth: contentContainerParentWidth
                                        }} keepTabsMounted={true}>
                                            <TabStripTab title={<TabTitle title="Address" selected={selectedTab === 0 ? true : false} />}>
                                                <AddressTab callbackHandleChangeDate={callbackHandleChangeDate} contentContainerWidth={contentContainerWidth} data={data} callbackCTSelected={callbackCTSelected}
                                                    callbackControlChange={handleChangeMainData} callbaclPLZChange={callbackHandlePLZChange} />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Patient" selected={selectedTab === 1 ? true : false} />}>
                                                <PatientTab callbackHandleChangeDate={callbackHandleChangeDate} contentContainerWidth={contentContainerWidth} data={data} callbackCTSelected={callbackCTSelected}
                                                    callbackControlChange={handleChangeMainData} callbackCodeSelected={callbackCodeSelected} />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Others" selected={selectedTab === 2 ? true : false} />}>
                                                <OtherTab MultiSelectSaved={MultiSelectSaved} callbackAdrTypeSelected={callbackAdrTypeSelected} contentContainerWidth={contentContainerWidth} data={data} callbackCTSelected={callbackCTSelected}
                                                    callbackControlChange={handleChangeMainData} />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Remarks" selected={selectedTab === 3 ? true : false} />}>
                                                <RemarksTab data={data} callbackControlChange={handleChangeMainData} contentContainerWidth={contentContainerWidth} />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Adr Pat Bld" selected={selectedTab === 4 ? true : false} />}>
                                                <AdrPatBldTab data={data} AdrPatBldData={oneData?.AdrPatBldData} contentContainerWidth={contentContainerWidth} callbackHandleAdrPatBldDataChange={HandleAdrPatBldDataChange} />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Adr Bd" selected={selectedTab === 5 ? true : false} />}>
                                                <AdrBdTab data={data} contentContainerWidth={contentContainerWidth} windowHeight={windowHeight}
                                                    adrBdGrpData={adrBdGrpData} adrBdAksData={adrBdAksData} adrBdTrfData={adrBdTrfData}
                                                />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Adr Ext PID" selected={selectedTab === 6 ? true : false} />}>
                                                <AdrExtPIDTab data={data} AdrExtPIDData={oneData?.AdrExtPIDData} contentContainerWidth={contentContainerWidth} callbackHandleAdrExtPIDDataChange={HandleAdrExtPIDDataChange} />
                                            </TabStripTab>
                                            <TabStripTab title={<TabTitle title="Type and Screen" selected={selectedTab === 7 ? true : false} />}>
                                                <TypeScreenTab data={data} AdrExternTSData={oneData?.AdrExternTSData} contentContainerWidth={contentContainerWidth} callbackHandleAdrExtTSDataChange={HandleAdrExtTSDataChange} />
                                            </TabStripTab>
                                        </TabStrip>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid" style={{ padding: 0, width: contentContainerParentWidth, minWidth: contentContainerParentWidth }}>
                                <div className="row">
                                    <div className="col pt5" style={{border: '0px solid red'}}>
                                        <div className="button-right">
                                            <ContextMenu
                                                vertical={true}
                                                onSelect={handleOnSelect}
                                                onClose={handleOnClose}
                                                show={show}
                                                offset={offset}
                                            >
                                                <MenuItem text="Scan Patient data" data={{ action: "scan" }} />
                                                <MenuItem text="Scan Patient PDF" data={{ action: "pdf" }} />

                                            </ContextMenu>
                                            <Button
                                                disabled={oneData?.DisabledScanControls}
                                                themeColor={'primary'}
                                                onClick={handleContextMenu} style={{ width: '120px', marginRight: 10, display: data.sPID === "" ? "none" : "inline-block" }}
                                            >
                                                Scanning
                                            </Button>
                                            <Button
                                                disabled={oneData?.DisableShowDocButton}
                                                themeColor={'primary'}
                                                onClick={showPatScanData} style={{ width: '120px', marginRight: 10, display: data.sPID === "" ? "none" : "inline-block" }}
                                            >
                                                ShowPatScanData
                                            </Button>
                                            <Button
                                                themeColor={'error'}
                                                onClick={deleteAddress} style={{ width: '120px', marginRight: 10, display: data.sPID === "" ? "none" : "inline-block" }}
                                            >
                                                {mui(userRights, "common", "cmdDelete", "Delete")}
                                            </Button>
                                            <Button style={{ width: '120px', marginRight: 10, display: 'inline-block' }}
                                                themeColor={'secondary'}
                                                onClick={cancelDialog}
                                            >
                                                {mui(userRights, "common", "cmdCancel", "Cancel")}
                                            </Button>
                                            <Button style={{ width: '120px', display: 'inline-block' }}
                                                themeColor={'primary'}
                                                onClick={saveDialog}
                                            >
                                                {mui(userRights, "common", "cmdSave", "Save")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>                                                                   
                            </div>
                        </>
                        }
                        <ScannerUI multipleScans={scannerUIData.multipleScans} selPDF={scannerUIData.selPDF} objScanProperties={scannerUIData.objScanProperties} source={scannerUIData.source}
                            showDialog={scannerUIData.showDialog} ScanningOver={ScanningOver}></ScannerUI>
                        <ViewScan sMandantID={viewScanDialogData.sMandantID} sPID={viewScanDialogData.sPID} sReqID={viewScanDialogData.sReqID}
                            sBldBgNr={viewScanDialogData.sBldBgNr} sBldBgType={viewScanDialogData.sBldBgType} sMaterialID={viewScanDialogData.sMaterialID}
                            sLotID={viewScanDialogData.sLotID} enm={viewScanDialogData.enm} showDialog={viewScanDialogData.showDialog} callbackDialogClosed={callbackViewScanDialogClosed} ></ViewScan>
                    </Window>
                )}
            </div>
            
        </>
    );
}

export default AddressDialog;
