
import { CResultEntry, ListItemData, selCodeDataType } from "../../types";
import {
    ListBox,
    processListBoxData,
    ListBoxItemClickEvent,
} from "@progress/kendo-react-listbox";
import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { StackLayout } from "@progress/kendo-react-layout";
import UserSession from "../UserSession";
import axios from 'axios'
import { DialogTitleBar, mui } from "../../Helper";
import { Dialog } from "@progress/kendo-react-dialogs";


const SELECTED_FIELD = "selected";

interface MultiSelectListProps {
    tablename: string;
    code: string;
    desc: string;
    selCode: string;
    mandantChanged: number;
    setSelected(sel: string): any;
    width: string;
    id: string;
    supplParam1: string;
    supplParam2: string;
    disabled: boolean;
    showDialog: boolean;
    objRE: CResultEntry;
}

type selCodeData = {
    mandantid: "",
    code: "",
    desc: "",
    selected: false
};

const MultiSelectList = (props: MultiSelectListProps) => {
    const [allFields, setAllFields] = useState<ListItemData[]>([]);
    const [selectedFields, setSelectedFields] = useState<ListItemData[]>([]);
    const [selNF, setSelNF] = useState<string>('');

    const [tableData, setTableData] = useState<selCodeData[]>([]);
    async function GetTableData() {
        const GetTableData_URL = UserSession.getSystemSettings().API_URL + 'SelectCode/gettabledata';

        const response = await axios.post(GetTableData_URL,
            JSON.stringify({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, "tableid": props.tablename,
                "code": props.code, "desc": props.desc, "supplParam1": props.supplParam1, "supplParam2": props.supplParam2,
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        setTableData(response?.data);
    }

    useEffect(() => {
        const binddata: ListItemData[] = [];
        let selobj = {} as ListItemData;
        var sIDs = "";


        const bindalldata: ListItemData[] = [];
        const bindseldata: ListItemData[] = [];

        tableData !== null && tableData.map((value: selCodeData, index) => {

            selobj.id = value.code
            selobj.name = value.desc
            selobj.selected = false

            if (props.selCode?.includes(selobj.id)) {

                bindseldata.push({ ...selobj });

                if (sIDs === '')
                    sIDs = selobj.id
                else
                    sIDs = sIDs + '�' + selobj.id
            }
            else {
                bindalldata.push({ ...selobj });
            }

        })
        //console.log("NFParamGroupData ue 1 - ", sIDs, bindalldata, bindseldata)

        setSelNF(sIDs);

        const newData: ListItemData[] = bindalldata.map((item: ListItemData) => {
            item[SELECTED_FIELD] = false;
            if (props.selCode?.includes(item.id)) {
                //console.log("NFParamGroupData ue - ", item.id)
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            }
            return item;
        });

        setSelNF(sIDs);

        const chosenData: ListItemData[] = bindseldata.map((item: ListItemData) => {
            item[SELECTED_FIELD] = false;
            if (props.selCode?.includes(item.id)) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            }
            return item;
        });

        setAllFields(newData);
        setSelectedFields(chosenData);


    }, [tableData])

    // on first time render of component
    useEffect(() => {

        GetTableData();

    }, [])

    // selection / deselection of listbox item
    const handleItemClick = (event: ListBoxItemClickEvent, allData: ListItemData[], selData: ListItemData[], allLocation: string) => {
        const newData: ListItemData[] = allData.map((item: ListItemData) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });
        const chosenData: ListItemData[] = selData.map((item: ListItemData) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });
        if (allLocation === 'FirstAll') {
            setAllFields(newData);
            setSelectedFields(chosenData);
        }
        else {
            setAllFields(chosenData);
            setSelectedFields(newData);
        }

    };

    // user chose to transfer/remove one or more items from left or right list
    const handleToolBarClick = (toolName: string) => {
        let result: any = processListBoxData(
            allFields,
            selectedFields,
            toolName,
            SELECTED_FIELD
        );
        setAllFields(result.listBoxOneData.map((item: any) => {
            item.selected = false;
            return item;
        }));
        setSelectedFields(result.listBoxTwoData.map((item: any) => {
            item.selected = false;
            return item;
        }));

        // to do traverse array and fill settingalue with name
        var sIDs = "";
        result.listBoxTwoData.forEach((o: ListItemData) => {
            if (sIDs === '')
                sIDs = o.id
            else {
                sIDs = sIDs + '�' + o.id
            }

        });
        setSelNF(sIDs);

    };

    const getFormTitle = () => {
        var str: string = "";
        str = mui(userRights, "resseldialog", "textTitleResSel", "Select Result") + ' ' + props.tablename
        return str;
    };


    const OkClick = async () => {

        props.setSelected (selNF)
        setVisible(false);
    };
    const CancelClick = async () => {
        
        setVisible(false);
    };
    const [visible, setVisible] = useState<boolean>(props.showDialog);

    return (
        <>
            <div>
             {visible && (
                    <Dialog title={<DialogTitleBar title={getFormTitle()} />} onClose={CancelClick} height={'420px'} width={'750px'} >
                        <div className="container" style={{ width: '700px' }}>
                            <div className="row">
                                <div className="col" style={{ width: '300px' }}>
                                    <ListBox
                                        style={{ height: '300px', width: '100%' }}
                                        data={allFields}
                                        textField="name"
                                        selectedField={SELECTED_FIELD}
                                        onItemClick={(e: ListBoxItemClickEvent) =>
                                            handleItemClick(e, allFields, selectedFields, 'FirstAll')
                                        }
                                    />
                                </div>
                                <div className="col" style={{ width: '50px' }}>
                                    <StackLayout orientation={"vertical"}>
                                        <Button className="buttons-container-button" icon="arrow-60-right"
                                            onClick={() => {
                                                handleToolBarClick('transferTo');
                                            }}
                                        >
                                        </Button>
                                        <Button className="buttons-container-button" icon="arrow-seek-right"
                                            onClick={() => {
                                                handleToolBarClick('transferAllTo');
                                            }}
                                        >
                                        </Button>
                                        <Button className="buttons-container-button" icon="arrow-60-left"
                                            onClick={() => {
                                                handleToolBarClick('transferFrom');
                                            }}
                                        >
                                        </Button>
                                        <Button className="buttons-container-button" icon="arrow-seek-left"
                                            onClick={() => {
                                                handleToolBarClick('transferAllFrom');
                                            }}
                                        >
                                        </Button>
                                    </StackLayout>
                                </div>
                                <div className="col" style={{ width: '300px' }}>
                                    <ListBox
                                        style={{ height: '300px', width: '100%' }}
                                        data={selectedFields}
                                        textField="name"
                                        selectedField={SELECTED_FIELD}
                                        onItemClick={(e: ListBoxItemClickEvent) =>
                                            handleItemClick(e, selectedFields, allFields, 'SecondAll')
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row"><div className="col">&nbsp;</div></div>
                            <div className="row">
                                <div className="col">
                                    <div className="button-right">
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            OkClick()
                                        }}>{mui(userRights, "common", "cmdOk", "Ok")}</Button>&nbsp;
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                            CancelClick()
                                        }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                  )}
            </div>
        </>

    );
};

export default MultiSelectList;