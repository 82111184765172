import { Input } from "@progress/kendo-react-inputs";
import { useEffect, useRef, useState } from "react";
import { dateInternalToSystem, formatTime } from "../../Helper";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import {
    IntlProvider,
    load,
    loadMessages,
    LocalizationProvider,
} from "@progress/kendo-react-intl";
import deMessages from "../../de.json";
import itMessages from "../../it.json";
import frMessages from "../../fr.json";
import UserSession from "../UserSession";

/* CLDR Data */
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import gbCaGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import gbDateFields from "cldr-dates-full/main/en-GB/dateFields.json";

import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";

import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";

import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";

import { CResultEntry, emptyREData } from "../../types";

load(
    likelySubtags,
    currencyData,
    weekData,
    deCaGregorian,
    deDateFields,
    gbCaGregorian,
    gbDateFields,
    itCaGregorian,
    itDateFields,
    frCaGregorian,
    frDateFields,
);
interface ICustomTime {
    defaulValue: string;
    id: string;
    source: string;
    label: string;
    required: boolean;
    className?: string;
    disabled: boolean;
    width: string;
    allowSecond: boolean;
    timeChanged: (value: string, source: string, supplParam1?: string, supplParam2?: string) => void;
    supplParam1?: string;
    supplParam2?: string;
    objRE?: CResultEntry;
}

const CustomTime = (props: ICustomTime) => {
    const dateInput = useRef<HTMLInputElement>();
    const [controlTime, setControlTime] = useState(props.defaulValue);

    useEffect(() => {
        setControlTime(props.defaulValue);
    }, [props])

    const handleChange = (e: any) => {
        const type = e.target.type
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value;
        const result = value.replace(/[^0-9\:]/gi, '');
        setControlTime(result);
    }

    const key_down = (ev: any) => {
        var keyCode: string = ev.keyCode.toString();
        if (keyCode === '65') {
            var curDate = new Date();
            var newTime: Date;

            newTime = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay(), curDate.getHours(), curDate.getMinutes(), curDate.getSeconds());
            setControlTime(formatTime(newTime.toLocaleString(), props.allowSecond));
            ev.preventDefault();
            return false;
         }
    };

 
    useEffect(() => {
        const lang: string = UserSession.getLanguage();
        if (lang === "de") {
            loadMessages(deMessages, "de-DE");
        }
        else if (lang === "it") {
            loadMessages(itMessages, "it-IT");
        }
        else if (lang === "fr") {
            loadMessages(frMessages, "fr-FR");
        }

    //    let element = document.getElementById(props.id);
    //    if (element) {
    //        element.addEventListener('keydown', key_down);
    //    }

    //    return () => {
    //        document.removeEventListener('keydown', key_down);
    //    };

    })

    const onBlur = (e: any) => {
        if (!ValidateDate(e.target.value)) {
            e.preventDefault();
            setTimeout(() => { if (dateInput.current) { console.log('focus'); dateInput.current.focus() } }, 200);
            return false;
        }

    };

    useEffect(() => {
        if (props.objRE !== undefined) {

            props.objRE.sRawResult = controlTime;
            props.objRE.sRecStatus = "CHG"

        }
    }, [controlTime])

    function ValidateDate(dt: string): boolean {
        if (dt === '') {
            props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
            setControlTime('');
            return true;
        }
        var curDate = new Date();
        var delimCount: number = dt.split(":").length - 1;
        var maxDelim: number = props.allowSecond ? 2 : 1;
        if (delimCount > maxDelim) {
            setControlTime('');
            props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
            return false;
        }
        dt = dt.split(":").join("");
        var newTime: Date;
        if (dt.length === 1 || dt.length === 2) { // only hour
            dt = dt.length === 1 ? "0" + dt : dt;
            if (Number(dt) < 0 || Number(dt) > 23) {
                setControlTime('');
                props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            newTime = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay(), Number(dt), curDate.getMinutes(), curDate.getSeconds());
            setControlTime(formatTime(newTime.toLocaleString(), props.allowSecond));
            props.timeChanged(formatTime(newTime.toLocaleString(), props.allowSecond), props.source, props.supplParam1, props.supplParam2);
        }
        else if (dt.length === 3 || dt.length === 4) { // hour and minute
            var hh: string = dt.substring(0, 2);
            var mm: string = dt.substring(2);
            mm = mm.length === 1 ? "0" + mm : mm;
            if (Number(hh) < 0 || Number(hh) > 23) {
                setControlTime('');
                props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            if (Number(mm) < 0 || Number(mm) > 59) {
                setControlTime('');
                props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            newTime = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay(), Number(hh), Number(mm), curDate.getSeconds());
            setControlTime(formatTime(newTime.toLocaleString(), props.allowSecond));
            props.timeChanged(formatTime(newTime.toLocaleString(), props.allowSecond), props.source, props.supplParam1, props.supplParam2);
        }
        else { // hour minute second             
            var hh: string = dt.substring(0, 2);
            var mm: string = dt.substring(2, 4);
            var ss: string = dt.substring(4);
            ss = ss.length === 1 ? "0" + ss : ss;
            if (Number(hh) < 1 || Number(hh) > 31) {
                setControlTime('');
                props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            if (Number(mm) < 0 || Number(mm) > 59) {
                setControlTime('');
                props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            if (Number(ss) < 0 || Number(ss) > 59) {
                setControlTime('');
                props.timeChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            newTime = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay(), Number(hh), Number(mm), Number(ss), 0);
            setControlTime(formatTime(newTime.toLocaleString(), props.allowSecond));
            props.timeChanged(formatTime(newTime.toLocaleString(), props.allowSecond), props.source, props.supplParam1, props.supplParam2);
        }
        return true;
    }

    return (
        <div style={{ display: "inline-flex", alignItems: 'center' }}>
            <div className="vertical-wrapper">
                {props.label !== "" && (<div className="vertical-wrapper-child"><Label className={props.required === true ? "mandatory" : ""}>{props.label}</Label></div>)} 
                <div className="vertical-wrapper-child">
                    <Input ref={dateInput} style={{ width: props.width }} onBlur={onBlur} className={props.className} disabled={props.disabled} required={props.required}
                        maxLength={props.allowSecond ? 8 : 5} onKeyDown={key_down}
                        autoComplete="off" id={props.id} type='text' defaultValue={props.defaulValue} value={controlTime} onChange={handleChange} />
                 </div>
            </div>
        </div>
    );
}

export default CustomTime;
