import { useEffect, useState } from "react";
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import { CMatInLabData,  matInLabOutput, selCodeDataType } from "../../types";
import React from "react";
import { SelectCode } from "../SelectCode/SelectCode";
import { mui } from "../../Helper";
import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";

interface MatDetailComponentProps {
    mandantid: string;
    data: CMatInLabData;
    MatInLabDetailData: matInLabOutput[];
    contentContainerWidth: number;
    updateMasterGrid(objReturn: matInLabOutput | null, objMainData: CMatInLabData): any;
}

var mainMatData: matInLabOutput[] = ([]);


const GridContext = React.createContext({});
const allInEdit = mainMatData.map((item) =>
    Object.assign({ inEdit: true }, item)
);

const MatDetailComponent = (props: MatDetailComponentProps) => {
    const [extrDate, setExtrDate] = React.useState<Date | null>(null);
    const [extrTime, setExtrTime] = React.useState<Date | null>(null);

    const callbackSelectCode = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {

        const binddata: matInLabOutput[] = [...matInLabDet];
        var tabElement: matInLabOutput;
        for (tabElement of binddata) {
            if (tabElement.matdesc === supplParam1) {

                if (tableid === "SampExt") {
                    tabElement.extractiontype = obj.code;
                    props.updateMasterGrid(tabElement, props.data)
                }
                
                break;
            }
        }
        setmatInLabDet(binddata);

    };

    const CustomCellEditExtractionType = (propsG: GridCellProps) => {
        return (
            <td style={{ textAlign: 'left', padding: '1px' }} >
                <SelectCode autocomplete={false} disabled={false} setSelectedTable={callbackSelectCode} id="ExtractionType"
                    tablename="SampExt" code="Code" desc="Descr" selCode={propsG.dataItem.extractiontype} mandantChanged={propsG.dataItem.mandantid} width="100%" supplParam1={propsG.dataItem.matdesc} supplParam2=""></SelectCode>
            </td>
        );
    };      

    const handleValYNCheck = (event: CheckboxChangeEvent, smatdesc : string ) => {
        const name = event.target.name
        const value = event.target.value === true ? '1' : '0'
        //console.log("handleValYNCheck", event.target, event.target.value)

        const binddata: matInLabOutput[] = [...matInLabDet];
        var tabElement: matInLabOutput;
        for (tabElement of binddata) {
            if (tabElement.matdesc === smatdesc) {
                if (name === "resultyn") {
                    tabElement.resultyn = value;
                    props.updateMasterGrid(tabElement, props.data)
                }
                else if (name === "inlaboryn") {
                    tabElement.inlaboryn = value;
                    props.updateMasterGrid(tabElement, props.data)
                }
                
                break;
            }
        }
        setmatInLabDet(binddata);
        
    };

    const UpdateDataInMaster = (objMainData: matInLabOutput) => {

        //const detmatbinddata: matInLabOutput[] = [...tabElement.collMatData];
        //var tabdetmatElement: matInLabOutput;
        //for (tabdetmatElement of detmatbinddata) {
        //    if (tabdetmatElement.matdesc === dataItem.matdesc) {
        //        tabdetmatElement.extractiontype = dataItem.extractiontype
        //        tabdetmatElement.inlaboryn = dataItem.inlaboryn
        //        tabdetmatElement.resultyn = dataItem.resultyn
        //    }
        //}

        //const binddata: CMatInLabData[] = [...props.data];
        //var tabElement: CMatInLabData;
        //for (tabElement of binddata) {
        //    if (tabElement.sReqID === objMainData.sReqID) {
               
        //        break;
        //    }
        //}
    }

    const CustomCellEditresultynField = (props: GridCellProps) => {
        const chkvalresultyn = props.dataItem['resultyn'];

        //console.log("chkbx", props.dataItem)
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >

                <Checkbox
                    size="large"
                    key={props.dataItem.mandantid + props.dataItem.reqid + props.dataItem.matdesc + "resultyn"}
                    id={props.dataItem.mandantid + props.dataItem.reqid + props.dataItem.matdesc + "resultyn"}
                    name={"resultyn" }
                    onChange={(event: CheckboxChangeEvent) => handleValYNCheck(event, props.dataItem.matdesc)}
                    checked={chkvalresultyn === "1" ? true : false} />

            </td>
        );

    };

    const CustomCellEditinlaborynField = (props: GridCellProps) => {
        const chkvalinlaboryn = props.dataItem['inlaboryn'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >

                <Checkbox
                    size="large"
                    key={props.dataItem.mandantid + props.dataItem.reqid + props.dataItem.matdesc + "inlaboryn"}
                    id={props.dataItem.mandantid + props.dataItem.reqid + props.dataItem.matdesc + "inlaboryn"}
                    name={"inlaboryn"}
                    onChange={(event: CheckboxChangeEvent) => handleValYNCheck(event, props.dataItem.matdesc)}
                    checked={chkvalinlaboryn === "1" ? true : false} />

            </td>
        );

    };

    const handleChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        var extrD: string = value;
        const binddata: matInLabOutput[] = [...matInLabDet];
        var tabElement: matInLabOutput;
        for (tabElement of binddata) {
            if (tabElement.matdesc === supplParam2 && tabElement.reqid === supplParam1) {
                tabElement.extrdate = extrD
                tabElement.extdatetime = tabElement.extrdate + " " + tabElement.extrtime 
                props.updateMasterGrid(tabElement, props.data)

                break;
            }
        }
        setmatInLabDet(binddata);
        
    };

    const handleChangeTime = (event: string, source: string, supplParam1?: string, supplParam2?: string) => {
        var extrT: string = event;
        const binddata: matInLabOutput[] = [...matInLabDet];
        var tabElement: matInLabOutput;
        for (tabElement of binddata) {
            if (tabElement.matdesc === supplParam2 && tabElement.reqid === supplParam1) {
                tabElement.extrtime = extrT
                tabElement.extdatetime = tabElement.extrdate + " " + tabElement.extrtime 
                props.updateMasterGrid(tabElement, props.data)

                break;
            }
        }
        setmatInLabDet(binddata);
    };

    const CustomCellEditExtrDateField = (props: GridCellProps) => {
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <CustomDate defaulValue={props.dataItem.extrdate} id={'txtExtractionDate'} source={'sExtractionDate'} label=""
                    required={false} disabled={false} width={'150px'} supplParam1={props.dataItem.reqid} supplParam2={props.dataItem.matdesc} className="k-input-header"
                    dateChanged={handleChangeDate}></CustomDate>
            </td>
        );

    };

    const CustomCellEditExtrTimeField = (props: GridCellProps) => {

        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <CustomTime allowSecond={true} timeChanged={handleChangeTime} width='100px' className='k-input-header' supplParam1={props.dataItem.reqid} supplParam2={props.dataItem.matdesc} defaulValue={props.dataItem.extrtime} id={'txtExtractionTime'} source={'sExtractionTime'} label="" required={false} disabled={false}></CustomTime>
            </td>
        );

    };

    const [matInLabDet, setmatInLabDet] = useState<matInLabOutput[]>([]);
    const [gridData, setGridData] = React.useState(allInEdit);

    useEffect(() => {
        setmatInLabDet(props.MatInLabDetailData)
        mainMatData = props.MatInLabDetailData

    }, [])   

    const itemChange = (e: GridItemChangeEvent) => {
        let newData = matInLabDet.map((item: matInLabOutput) => {
            if (item.matdesc === e.dataItem.matdesc) {
                
            }
            return item;
        });
        setmatInLabDet(newData);
    };

    return (
        <>

            <div className="row">
                <div className="col">
                    <div style={{ overflowX: 'hidden' }}>
                    <section style={{ width: "20%", float: "left" }}>
                        <p>
                            <strong>{props.data.sPIDDetails}</strong>
                            { }
                            </p>
                               
                        <p>
                            <strong>{props.data.sOrdProvDetails}</strong>
                            {/*<strong>{mui(userRights, "matdetcomp", "line2", "Order Provider:")}</strong> {props.data.sOrdProvDetails}*/}
                            </p>
                              
                        <p>
                            <strong>{props.data.sLatestAntibody}</strong>
                            {/*<strong>{mui(userRights, "matdetcomp", "line3", "Room:")}</strong> {props.data.sRoom}*/}
                            </p>
                            {/*<p>*/}
                            {/*<strong>{mui(userRights, "matdetcomp", "line4", "Latest Antibody:")}</strong> {props.data.sLatestAntibody}*/}
                            {/*</p>*/}

                        </section>
                        <GridContext.Provider
                            value={{ setmatInLabDet }}
                        >
                            <Grid
                                style={{
                                width: "80%"
                                }}
                                data={matInLabDet}
                                dataItemKey={"matdetails"}
                                editField="inEdit"
                                onItemChange={itemChange}
                            >

                                <GridColumn field="matdetails" title={mui(userRights, "matdetcomp", "col8_MatDetails", "MatDetails")} width="250px" />
                                <GridColumn field="inlaboryn" title={mui(userRights, "matdetcomp", "col_inlaboryn", "inlaboryn")} width="70px" editable={true} cell={CustomCellEditinlaborynField } />
                                <GridColumn field="resultyn" title={mui(userRights, "matdetcomp", "col_resultyn", "resultyn")} width="70px" editable={true} cell={CustomCellEditresultynField} />
                                <GridColumn field="extrdate" title={mui(userRights, "matdetcomp", "col9_ExtDateTime", "ExtDateTime")} editable={true} cell={CustomCellEditExtrDateField} width="200px" />
                                <GridColumn field="extrtime" title={mui(userRights, "matdetcomp", "col9_ExtDateTime", "ExtDateTime")} editable={true} cell={CustomCellEditExtrTimeField} width="150px" />
                                <GridColumn field="extractiontype" title={mui(userRights, "matdetcomp", "col_ExtractionType", "ExtractionType")} editable={true} width="185px" cell={CustomCellEditExtractionType} />
                                <GridColumn field="paramresult" title={mui(userRights, "matdetcomp", "col10_ParamResult", "ParamResult")} editable={false} width="200px" />
                                <GridColumn field="collectorid" title={mui(userRights, "matdetcomp", "col11_CollectorID", "CollectorID")} width="150px" />

                            </Grid>
                        </GridContext.Provider>
                    </div>
                </div>
            </div>


        </>
    );
}

export default MatDetailComponent;